import * as api from './processing-center-api.js'
import { mapState } from 'vuex'
import orderOperation from '@/mixins/order-operation.js'
export default {

    mixins: [orderOperation],
    computed: {
        ...mapState({
            untreatedList: s => s.untreatedList,
            beingProcessedList: s => s.beingProcessedList,
            processedList: s => s.processedList,
        })
    },
    async mounted() {

        await this.codeHandle()
        this.findRepaire()
    },
    methods: {

        /* handle */
        toProcessingList (type) {

            this.$router.push('/processing-list/' + type)

        },
        
        /* api */
        // code处理, 存入openId
        codeHandle () {
            let openId = localStorage.getItem('man-openId')
            if (openId && openId != 'null') return
            
            let code = localStorage.getItem('man-code')
            api.findOpenIdByCode({code}).then(data => {
                if (data) {
                    localStorage.setItem('man-openId', data.data.resultObject)
                }
            })
        },
    }
}