import * as api from './order-operation-api.js'
import { Dialog } from 'vant';
import { mapMutations } from 'vuex';
export default {
    
    methods: {
        ...mapMutations (['UNTREATED_LIST', 'BEING_PROCESSED_LIST', 'PROCESSED_LIST']),

        /* api */
        
        // 接单
        orderOperation (t, cstate, cb = () => {}) {

            let {
                creId,
            } = t

            let message

            if (cstate == 1) message = '请确认进行接单操作'
            else if (cstate == 2) message = '请确认维修已完成'

            Dialog.confirm({
                message,
            })
            .then(() => {

                this.editRepair({
                    chandlerId: localStorage.getItem('man-openId'), // openId
                    creId,
                    cstate,
                }, cb)
            })
            .catch(() => {});
            
        },

        // 查
        findRepaire () {

            this.UNTREATED_LIST([])
            this.BEING_PROCESSED_LIST([])
            this.PROCESSED_LIST([])

            api.findRepaire({
                chandlerId: localStorage.getItem('man-openId'),
                capplicantId: localStorage.getItem('man-openId'),
            }).then(data => {
                if(data) {
                    let d = data.data.resultObject

                    let 
                    untreatedList = [],
                    beingProcessedList = [],
                    processedList = []

                    d.map(t => {
                        if (t.cstate === 0) {
                            untreatedList.push(t)
                        } else if (t.cstate === 1) {
                            beingProcessedList.push(t)
                        } else if (t.cstate === 2) {
                            processedList.push(t)
                        }
                    })

                    this.UNTREATED_LIST(untreatedList)
                    this.BEING_PROCESSED_LIST(beingProcessedList)
                    this.PROCESSED_LIST(processedList)
                    
                }
            })
        },

        // 查人员
        findRepairPersonnel () {
            this.popupList = []
            
            api.findRepairPersonnel ({}).then(data => {
                if (data) {
                    this.popupList = data.data.resultObject

                    this.popupList.map(t => {
                        t.text = t.cname
                    })
                }
            })
        },

        // 修改
        editRepair (args, cb = () => {}) {
            api.editRepair(args).then(data => {
                if (data) {
                    this.$message({ message: '操作成功', type: 'success' })
                    cb (args.creId)
                }
            })
        },
    },
}