import * as API from '@/api/index'

/* 查 */
export const findRepaire = (params) => {
  return API.POST('/WxRepair/findRepaire', params);
}

/* 查 */
export const findOpenIdByCode = (params) => {
  return API.POST('/WxRepair/findOpenIdByCode', params);
}
