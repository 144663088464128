import * as API from '@/api/index'

/* 查 */
export const findRepaire = (params) => {
  return API.POST('/WxRepair/findRepaire', params);
}

/* 修改 */
export const editRepair = (params) => {
    return API.POST('/WxRepair/editRepair', params);
}

/* 查询人员 */
export const findRepairPersonnel = (params) => {
  return API.GET('/WxRepair/findRepairPersonnel', params);
}
